import React from 'react'
import Tick from '../img/Tick.png'
import Asset19 from '../img/Asset19.png'
import Asset20 from '../img/Asset20.png'
import Asset21 from '../img/Asset21.png'
import Asset22 from '../img/Asset22.png'
import Asset23 from '../img/Asset23.png'
import Asset24 from '../img/Asset24.png'
import Asset25 from '../img/Asset25.png'
import Asset26 from '../img/Asset26.png'
import object from '../img/object.png'

export default function Object({objectProp}) {
  return (
    // đối tượng tuyển sinh
    <div className={ objectProp.isMobile ?
        "w-full h-[600px] flex flex-wrap"
        : objectProp.isTablet ? "w-full h-[600px] flex flex-wrap"
        : "max-w-[1280px] w-full h-[600px] m-auto flex"
    } id="object">
        <div className={ objectProp.isMobile ?
            "w-[100%] h-[100%]"
            : objectProp.isTablet ? "w-[100%] h-[100%]"
            : "w-[50%] h-[100%]"
        }>
            <div className={ objectProp.isMobile ?
                "w-full h-[350px] mt-[30%] translate-y-[-50%]"
                : objectProp.isTablet ? "w-full h-[350px] mt-[35%] translate-y-[-50%]"
                : "w-full h-[350px] mt-[50%] translate-y-[-50%]"
            }>
                <p className={ objectProp.isMobile ?
                    "text-[25px] font-bold text-[#0046CD] text-center"
                    : objectProp.isTablet ? "text-[25px] font-bold text-[#0046CD] text-center"
                    : "text-[35px] font-bold text-[#0046CD] text-center"
                }>ĐỐI TƯỢNG TUYỂN SINH</p>
                <div className="w-[65%] m-auto mt-[20px]">
                    <div className="flex">
                        <span>
                            <img src={Tick} className="w-[17px] mt-[5px]" />
                        </span>
                        <span className="text-[#4E4F4F] text-[22px] ml-[15px]">Tốt nghiệp THPT hoặc tương đương;</span>
                    </div>
                    <div className="flex">
                        <span>
                            <img src={Tick} className="w-[17px] mt-[5px]" />
                        </span>
                        <span className="text-[#4E4F4F] text-[22px] ml-[15px]">Tốt nghiệp Trung cấp , Cao đẳng;</span>
                    </div>
                    {/* <div className="flex">
                        <span>
                            <img src={Tick} className="w-[17px] mt-[5px]" />
                        </span>
                        <span className="text-[#4E4F4F] text-[22px] ml-[15px]">Tốt nghiệp Đại học trở lên</span>
                    </div> */}
                    <p className="text-[22px] font-bold text-[#FF9300] mt-[30px]">Thời gian đào tạo</p>
                    <div className="flex items-center">
                        <p className={ !objectProp.isMobile ?
                            "bg-[#FF9300] text-[22px] text-white w-[65%] text-center rounded-[20px] py-[4px] mt-[5px] ml-[10px]"
                            : "bg-[#FF9300] text-[20px] text-white w-[65%] text-center rounded-[20px] py-[4px] px-[5px] mt-[5px] ml-[10px]"
                        }>Từ 3 đến 4 năm</p>
                        <div className="w-[12px] h-[12px] bg-[#FF9300] rounded-[50%] ml-[30px]"></div>
                        <div className="w-[12px] h-[12px] bg-[#FFBE66] rounded-[50%] ml-[18px]"></div>
                        <div className="w-[12px] h-[12px] bg-[#FFDEB2] rounded-[50%] ml-[18px]"></div>
                    </div>
                </div>
            </div>
        </div>
        
        {
            objectProp.isMobile ?
            <div className="w-full h-auto mt-[-250px] px-[20px]">
                <img src={object} />
            </div>

            : objectProp.isTablet ?
            <div className="w-full h-auto mt-[-220px] ml-[50%] translate-x-[-50%]">
                <img src={object} />
            </div>

            :
            <div className={ !objectProp.isMobile ?
                "w-[50%] h-[100%] py-[80px] pr-[80px]"
                : "w-[100%] h-[100%] mt-[-200px] mx-[20px]"
            }>
                <div className={ !objectProp.isMobile ?
                    "w-full h-[50%] flex"
                    : "w-full h-auto flex"
                }>
                    <div className="w-[240px] h-[100%] ">
                        <img src={Asset19} className="w-full h-full" />
                    </div>
                    <div className="w-[240px] h-full ml-[20px]">
                        <div className="w-full h-[50%] pb-[10px]">
                            <img src={Asset20} className="w-[100px] h-full"/>
                        </div>
                        <div className="w-full h-[50%] flex pt-[10px]">
                            <img src={Asset21} className="w-[100px] h-full"/>
                            <img src={Asset22} className="w-[100px] h-full bg-[#FFCE66] rounded-[10px] ml-[20px]"/>   
                        </div>
                    </div>
                </div>
    
                <div className="w-full h-[50%] flex mt-[20px]">
                    <div className="w-[240px] h-full">
                        <div className="w-full h-[50%] pb-[10px]">
                            <img src={Asset23} className="w-[100px] h-full ml-auto"/>
                        </div>
                    </div>
                    <div className="w-[240px] h-full ml-[20px]">
                        <div className="w-full h-[50%] pb-[10px] flex">
                            <img src={Asset24} className="w-[100px] h-full"/>
                            <img src={Asset25} className="w-[100px] h-full ml-[20px]"/>
                        </div>
                        <div className="w-full h-[50%] pb-[10px] flex">
                            <img src={Asset26} className="w-[100px] h-full"/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}
